import React, { FunctionComponent } from 'react'
import { ApiErrorResponse, isApiErrorResponseWithApiSubErrors } from '@wh/common/chapter/lib/errors'
import { Text } from '@wh-components/core/Text/Text'
import { Alert } from '@wh-components/core/Alert/Alert'
import WarningIcon from '@wh-components/icons/AlertWarning'
import { SpaceProps } from '@wh-components/system/space'

interface ApiErrorAlertProps extends SpaceProps {
    title?: string
    error: ApiErrorResponse
    fieldToLabelMap?: Record<string, string | undefined>
}

export const ApiErrorAlert: FunctionComponent<ApiErrorAlertProps> = ({ title, error, fieldToLabelMap, ...spaceProps }) => (
    <Alert title={title || error.title} variant="error" Icon={WarningIcon} testId="alert-error" {...spaceProps}>
        {title && error.title && <Text as="p">{error.title}</Text>}
        {isApiErrorResponseWithApiSubErrors(error) ? (
            error.errors.map((err) => (
                <Text as="p" key={`${err.field}-${err.message}`} testId="errorAlertText">
                    {`${fieldToLabelMap?.[err.field] || err.field}: ${err.message}`}
                </Text>
            ))
        ) : (
            <Text testId="errorAlertText">{error.message}</Text>
        )}
    </Alert>
)
