import { FormikHelpers, FormikProps, FormikValues } from 'formik'
import {
    ApiSubError,
    DEFAULT_ERROR_RESPONSE,
    isApiErrorResponseWithApiSubErrors,
    wrapInApiErrorIfNecessary,
} from '@wh/common/chapter/lib/errors'
import { ChangeEvent, InputHTMLAttributes, OptionHTMLAttributes } from 'react'

export const requiredFieldProps = <Values extends FormikValues, Field extends keyof Values & string>(
    formProps: FormikProps<Values>,
    fieldRequiredness: (values: Values) => Record<Field, boolean>,
    field: Field,
) => {
    const isRequired = fieldRequiredness(formProps.values)[field]
    return {
        required: isRequired,
        showRequiredLabel: !isRequired,
    }
}

export const labelFieldProp = <Values extends FormikValues, Field extends keyof Values & string>(
    _formProps: FormikProps<Values>,
    fieldLabelMap: Record<Field, string>,
    field: Field,
) => {
    return {
        label: fieldLabelMap[field],
    }
}

export const inputProps = <
    Values extends FormikValues,
    Field extends keyof Values & string,
    Element extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
>(
    formProps: FormikProps<Values>,
    fieldLabelMap: Record<Field, string>,
    fieldRequiredness: (values: Values) => Record<Field, boolean>,
    field: Field,
    trimOnBlur?: boolean,
    onChange?: (value: ChangeEvent<Element>) => void,
) => {
    return {
        value: formProps.values[field] as InputHTMLAttributes<unknown>['value'],
        name: field,
        id: field,
        testId: `${field}-input`,
        onChange:
            typeof onChange === 'undefined'
                ? formProps.handleChange
                : (e: ChangeEvent<Element>) => {
                      onChange(e)
                      formProps.handleChange(e)
                  },
        onBlur: trimOnBlur
            ? (e: unknown) => {
                  let value = formProps.values[field] as string
                  value = value.trim()
                  formProps.setFieldValue(field as string, value)
                  formProps.handleBlur(e)
              }
            : formProps.handleBlur,
        state: getFormElementStatus(field, formProps),
        comment: isFormElementError(field, formProps) ? (formProps.errors[field] as string) : undefined,
        disabled: formProps.isSubmitting,
        ...labelFieldProp(formProps, fieldLabelMap, field),
        ...requiredFieldProps(formProps, fieldRequiredness, field),
    }
}

export const inputGroupProps = <Values extends FormikValues, Field extends keyof Values & string>(
    formProps: FormikProps<Values>,
    fieldLabelMap: Record<Field, string>,
    fieldRequiredness: (values: Values) => Record<Field, boolean>,
    field: Field,
) => {
    return {
        name: field,
        id: field,
        testId: `${field}-input-group`,
        state: getFormElementStatus(field, formProps),
        comment: isFormElementError(field, formProps) ? (formProps.errors[field] as string) : undefined,
        disabled: formProps.isSubmitting,
        ...labelFieldProp(formProps, fieldLabelMap, field),
        ...requiredFieldProps(formProps, fieldRequiredness, field),
    }
}

export const radioInputProps = <Values extends FormikValues, Field extends keyof Values & string>(
    formProps: FormikProps<Values>,
    field: Field,
    fieldValue: InputHTMLAttributes<unknown>['value'],
) => {
    return {
        checked: formProps.values[field] === fieldValue,
        id: `${field}-${fieldValue}`,
        testId: `${field}-input-radio-${fieldValue}`,
        onChange: formProps.handleChange,
    }
}

export const selectedInputProps = <Values extends FormikValues, Field extends keyof Values & string>(
    formProps: FormikProps<Values>,
    fieldLabelMap: Record<Field, string>,
    fieldRequiredness: (values: Values) => Record<Field, boolean>,
    field: Field,
    onChange?: (value: ChangeEvent<HTMLSelectElement>) => void,
) => {
    return {
        selected: formProps.values[field] as OptionHTMLAttributes<unknown>['selected'],
        ...inputProps(formProps, fieldLabelMap, fieldRequiredness, field, undefined, onChange),
    }
}

export const checkboxInputProps = <Values extends FormikValues, Field extends keyof Values & string>(
    formProps: FormikProps<Values>,
    fieldLabelMap: Record<Field, string>,
    fieldRequiredness: (values: Values) => Record<Field, boolean>,
    field: Field,
    onChange?: (value: ChangeEvent<HTMLInputElement>) => void,
) => {
    return {
        checked: formProps.values[field] as InputHTMLAttributes<unknown>['checked'],
        ...inputProps(formProps, fieldLabelMap, fieldRequiredness, field, undefined, onChange),
    }
}

export const isFormElementError = <Values extends FormikValues, Field extends keyof Values & string>(
    field: Field,
    props: FormikProps<Values>,
): boolean => {
    if (props.errors[field] && props.touched[field]) {
        return true
    }

    return false
}

export const getFormElementStatus = <Values extends FormikValues, Field extends keyof Values & string>(
    field: Field,
    props: FormikProps<Values>,
): 'error' | 'normal' => {
    if (props.errors[field] && props.touched[field]) {
        return 'error'
    }

    return 'normal'
}

export const mapErrorToFormik = <T>(error: unknown, formikHelpers: FormikHelpers<T>) => {
    const apiError = wrapInApiErrorIfNecessary(error)
    const validationErrors = isApiErrorResponseWithApiSubErrors(apiError.errorResponse) ? apiError.errorResponse.errors || [] : []

    validationErrors.forEach((err: ApiSubError) => {
        const field: (keyof T & string) | null | undefined | '' = err.attributeKey && (err.attributeKey.toLowerCase() as keyof T & string)
        if (field) {
            formikHelpers.setFieldError(field, err.message)
        }
    })

    if (validationErrors.length === 0 && apiError.errorResponse.message === null && apiError.errorResponse.title === null) {
        apiError.errorResponse.title = DEFAULT_ERROR_RESPONSE.title
        apiError.errorResponse.message = DEFAULT_ERROR_RESPONSE.message
    }

    formikHelpers.setStatus(apiError.errorResponse)
}
