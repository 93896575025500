import React, { ChangeEvent, ReactNode, Ref } from 'react'
import { Input } from '@wh-components/core/FormElements/Input/Input'
import { Checkbox } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { Toggle } from '@wh-components/core/FormElements/Toggle/Toggle'
import { RadioGroup, RadioGroupChildRadioProps } from '@wh-components/core/FormElements/Radio/RadioGroup'
import { PasswordInput } from '@wh-components/core/FormElements/PasswordInput/PasswordInput'
import { Textarea } from '@wh-components/core/FormElements/Textarea/Textarea'
import { Select } from '@wh-components/core/FormElements/Select/Select'
import { BubbleRadioGroup, BubbleRadioGroupChildRadioProps } from '@wh-components/core/FormElements/BubbleRadio/BubbleRadioGroup'
import { FormikValues, FormikProps } from 'formik'
import {
    inputProps,
    selectedInputProps,
    inputGroupProps,
    radioInputProps,
    checkboxInputProps,
} from '@wh/common/chapter/components/Formik/formikHelpers'
import { Box } from '@wh-components/core/Box/Box'

// ---- Input ----

type InputProps = React.ComponentProps<typeof Input>

type FormikInputProps<Values extends FormikValues, Field extends Extract<keyof Values, string>> = Omit<
    InputProps,
    // excluded props are prefilled by inputProps(); showRequiredLabel is not excluded to allow overriding; ref is excluded as we cannot use forwardRef because of generics
    Exclude<keyof ReturnType<typeof inputProps>, 'showRequiredLabel'> | 'ref'
> & {
    formProps: FormikProps<Values>
    fieldLabelMap: Record<Field, string>
    fieldRequiredness: (values: Values) => Record<Field, boolean>
    field: Field
    disabledOverride?: boolean
    commentOverride?: string
    trimOnBlur?: boolean
    innerRef?: Ref<HTMLInputElement>
}

export const FormikInput = <Values extends FormikValues, Field extends Extract<keyof Values, string>>({
    formProps,
    fieldLabelMap,
    fieldRequiredness,
    field,
    disabledOverride,
    commentOverride,
    trimOnBlur,
    innerRef,
    ...props
}: FormikInputProps<Values, Field>) => {
    const computedInputProps = inputProps(formProps, fieldLabelMap, fieldRequiredness, field, trimOnBlur)
    return (
        <Input
            ref={innerRef}
            {...computedInputProps}
            disabled={typeof disabledOverride === 'undefined' ? computedInputProps.disabled : disabledOverride}
            comment={typeof commentOverride === 'undefined' ? computedInputProps.comment : commentOverride}
            {...props}
        />
    )
}

// ---- PasswordInput ----

type PasswordInputProps = React.ComponentProps<typeof PasswordInput>

type FormikPasswordInputProps<Values extends FormikValues, Field extends Extract<keyof Values, string>> = Omit<
    PasswordInputProps,
    // excluded props are prefilled by inputProps(); showRequiredLabel is not excluded to allow overriding; ref is excluded as we cannot use forwardRef because of generics
    Exclude<keyof ReturnType<typeof inputProps>, 'showRequiredLabel'> | 'ref'
> & {
    formProps: FormikProps<Values>
    fieldLabelMap: Record<Field, string>
    fieldRequiredness: (values: Values) => Record<Field, boolean>
    field: Field
    innerRef?: Ref<HTMLInputElement>
}

export const FormikPasswordInput = <Values extends FormikValues, Field extends Extract<keyof Values, string>>({
    formProps,
    fieldLabelMap,
    fieldRequiredness,
    field,
    innerRef,
    ...props
}: FormikPasswordInputProps<Values, Field>) => (
    <PasswordInput ref={innerRef} {...inputProps(formProps, fieldLabelMap, fieldRequiredness, field)} {...props} />
)

// ---- Textarea ----

type TextareaProps = React.ComponentProps<typeof Textarea>

type FormikTextareaProps<Values extends FormikValues, Field extends Extract<keyof Values, string>> = Omit<
    TextareaProps,
    // excluded props are prefilled by inputProps(); showRequiredLabel is not excluded to allow overriding; ref is excluded as we cannot use forwardRef because of generics
    Exclude<keyof ReturnType<typeof inputProps>, 'showRequiredLabel'> | 'ref'
> & {
    formProps: FormikProps<Values>
    fieldLabelMap: Record<Field, string>
    fieldRequiredness: (values: Values) => Record<Field, boolean>
    field: Field
    innerRef?: Ref<HTMLTextAreaElement>
}

export const FormikTextarea = <Values extends FormikValues, Field extends Extract<keyof Values, string>>({
    formProps,
    fieldLabelMap,
    fieldRequiredness,
    field,
    innerRef,
    ...props
}: FormikTextareaProps<Values, Field>) => (
    <Textarea ref={innerRef} {...inputProps(formProps, fieldLabelMap, fieldRequiredness, field)} {...props} />
)

// ---- Select ----

type SelectProps = React.ComponentProps<typeof Select>

type FormikSelectProps<Values extends FormikValues, Field extends Extract<keyof Values, string>> = Omit<
    SelectProps,
    // excluded props are prefilled by selectedInputProps(); showRequiredLabel is not excluded to allow overriding; ref is excluded as we cannot use forwardRef because of generics
    Exclude<keyof ReturnType<typeof selectedInputProps>, 'showRequiredLabel'> | 'ref'
> & {
    formProps: FormikProps<Values>
    fieldLabelMap: Record<Field, string>
    fieldRequiredness: (values: Values) => Record<Field, boolean>
    field: Field
    onChange?: (value: ChangeEvent<HTMLSelectElement>) => void // TODO find a more generic way to allow side effects
}

export const FormikSelect = <Values extends FormikValues, Field extends Extract<keyof Values, string>>({
    formProps,
    fieldLabelMap,
    fieldRequiredness,
    field,
    onChange,
    ...props
}: FormikSelectProps<Values, Field>) => (
    <Select {...selectedInputProps(formProps, fieldLabelMap, fieldRequiredness, field, onChange)} {...props} />
)

// ---- RadioGroup ----

type RadioGroupProps = React.ComponentProps<typeof RadioGroup>

type RadioGroupChildRadioPropsWithoutPrefilledProps = Omit<
    RadioGroupChildRadioProps,
    // excluded fields are prefilled by radioInputProps(); ref is excluded as we cannot use forwardRef because of generics
    keyof ReturnType<typeof radioInputProps> | 'ref'
>

type FormikRadioGroupProps<Values extends FormikValues, Field extends Extract<keyof Values, string>> = Omit<
    RadioGroupProps,
    // excluded props are prefilled by inputGroupProps() + the radio prop is prefilled too; showRequiredLabel is not excluded to allow overriding
    Exclude<keyof ReturnType<typeof inputGroupProps>, 'showRequiredLabel'> | 'radios'
> & {
    formProps: FormikProps<Values>
    fieldLabelMap: Record<Field, string>
    fieldRequiredness: (values: Values) => Record<Field, boolean>
    field: Field
    radios: RadioGroupChildRadioPropsWithoutPrefilledProps[]
    commentOverride?: string
}

export const FormikRadioGroup = <Values extends FormikValues, Field extends Extract<keyof Values, string>>({
    formProps,
    fieldLabelMap,
    fieldRequiredness,
    field,
    radios,
    commentOverride,
    ...props
}: FormikRadioGroupProps<Values, Field>) => {
    const complementedRadios = radios.map((r) => {
        return { ...r, ...radioInputProps(formProps, field, r.value) }
    })
    const calculatedInputGroupProps = inputGroupProps(formProps, fieldLabelMap, fieldRequiredness, field)
    return (
        <RadioGroup
            radios={complementedRadios}
            {...calculatedInputGroupProps}
            comment={typeof commentOverride === 'undefined' ? calculatedInputGroupProps.comment : commentOverride}
            {...props}
        />
    )
}

// ---- BubbleRadioGroup ----

// type BubbleRadioProps = React.ComponentProps<typeof BubbleRadio>

// type FormikBubbleRadioProps<Values extends FormikValues, Field extends Extract<keyof Values, string>> = Omit<
//     BubbleRadioProps,
//     Exclude<keyof ReturnType<typeof bubbleRadioProps>, 'showRequiredLabel'> | 'ref'
// > & {
//     formProps: FormikProps<Values>
//     fieldLabelMap: Record<Field, string>
//     fieldRequiredness: (values: Values) => Record<Field, boolean>
//     value: string
//     label: string
//     field: Field
//     onChange?: (value: ChangeEvent<HTMLInputElement>) => void
// }

// export const FormikBubbleRadio = <Values extends FormikValues, Field extends Extract<keyof Values, string>>({
//     formProps,
//     value,
//     field,
//     fieldLabelMap,
//     fieldRequiredness,
//     ...props
// }: FormikBubbleRadioProps<Values, Field>) => {
//     const radioProps = bubbleRadioProps(formProps, field, value, fieldLabelMap, fieldRequiredness)
//     return <BubbleRadio {...radioProps} {...props} />
// }

type BubbleRadioGroupProps = React.ComponentProps<typeof BubbleRadioGroup>

type BubbleRadioGroupChildBubbleRadioPropsWithoutPrefilledProps = Omit<
    BubbleRadioGroupChildRadioProps,
    // excluded fields are prefilled by radioInputProps(); ref is excluded as we cannot use forwardRef because of generics
    keyof ReturnType<typeof radioInputProps> | 'ref'
>

type FormikBubbleRadioGroupProps<Values extends FormikValues, Field extends Extract<keyof Values, string>> = Omit<
    BubbleRadioGroupProps,
    // excluded props are prefilled by inputGroupProps() + the radio prop is prefilled too; showRequiredLabel is not excluded to allow overriding
    Exclude<keyof ReturnType<typeof inputGroupProps>, 'showRequiredLabel'> | 'radios'
> & {
    formProps: FormikProps<Values>
    fieldLabelMap: Record<Field, string>
    fieldRequiredness: (values: Values) => Record<Field, boolean>
    field: Field
    radios: BubbleRadioGroupChildBubbleRadioPropsWithoutPrefilledProps[]
    commentOverride?: string
}

export const FormikBubbleRadioGroup = <Values extends FormikValues, Field extends Extract<keyof Values, string>>({
    formProps,
    fieldLabelMap,
    fieldRequiredness,
    field,
    radios,
    commentOverride,
    ...props
}: FormikBubbleRadioGroupProps<Values, Field>) => {
    const complementedBubbleRadios = radios.map((r) => {
        return { ...r, ...radioInputProps(formProps, field, r.value) }
    })
    const calculatedInputGroupProps = inputGroupProps(formProps, fieldLabelMap, fieldRequiredness, field)
    return (
        <BubbleRadioGroup
            radios={complementedBubbleRadios}
            {...calculatedInputGroupProps}
            comment={typeof commentOverride === 'undefined' ? calculatedInputGroupProps.comment : commentOverride}
            {...props}
        />
    )
}

// ---- Checkbox ----

type CheckboxProps = React.ComponentProps<typeof Checkbox>

type FormikCheckboxProps<Values extends FormikValues, Field extends Extract<keyof Values, string>> = Omit<
    CheckboxProps,
    // excluded props are prefilled by checkboxInputProps(); showRequiredLabel is not excluded to allow overriding; ref is excluded as we cannot use forwardRef because of generics
    Exclude<keyof ReturnType<typeof checkboxInputProps>, 'showRequiredLabel'> | 'ref'
> & {
    formProps: FormikProps<Values>
    fieldLabelMap: Record<Field, string>
    fieldRequiredness: (values: Values) => Record<Field, boolean>
    field: Field
    labelOverride?: ReactNode // workaround because not all components support this, and therefore we cannot make fieldLabelMap return string | ReactNode
}

export const FormikCheckbox = <Values extends FormikValues, Field extends Extract<keyof Values, string>>({
    formProps,
    fieldLabelMap,
    fieldRequiredness,
    field,
    labelOverride,
    ...props
}: FormikCheckboxProps<Values, Field>) => {
    const calculatedCheckboxInputProps = checkboxInputProps(formProps, fieldLabelMap, fieldRequiredness, field)
    return (
        <Checkbox
            {...calculatedCheckboxInputProps}
            label={typeof labelOverride === 'undefined' ? calculatedCheckboxInputProps.label : labelOverride}
            {...props}
        />
    )
}

// ---- Toggle ----

type ToggleProps = React.ComponentProps<typeof Toggle>

type FormikToggleProps<Values extends FormikValues, Field extends Extract<keyof Values, string>> = Omit<
    ToggleProps,
    // excluded props are prefilled by checkboxInputProps(); showRequiredLabel is not excluded to allow overriding; ref is excluded as we cannot use forwardRef because of generics
    Exclude<keyof ReturnType<typeof checkboxInputProps>, 'showRequiredLabel'> | 'ref'
> & {
    formProps: FormikProps<Values>
    fieldLabelMap: Record<Field, string>
    fieldRequiredness: (values: Values) => Record<Field, boolean>
    field: Field
    disabled?: boolean
    labelOverride?: ReactNode // workaround because not all components support this, and therefore we cannot make fieldLabelMap return string | ReactNode
    onChange?: (value: ChangeEvent<HTMLInputElement>) => void
}

export const FormikToggle = <Values extends FormikValues, Field extends Extract<keyof Values, string>>({
    formProps,
    fieldLabelMap,
    fieldRequiredness,
    field,
    labelOverride,
    disabled = false,
    onChange,
    ...props
}: FormikToggleProps<Values, Field>) => {
    const calculatedCheckboxInputProps = checkboxInputProps(formProps, fieldLabelMap, fieldRequiredness, field, onChange)
    return (
        <Toggle
            {...calculatedCheckboxInputProps}
            disabled={disabled}
            label={<Box marginLeft="sm">{typeof labelOverride === 'undefined' ? calculatedCheckboxInputProps.label : labelOverride}</Box>}
            {...props}
        />
    )
}
