import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export const genericValidationErrorMessages = {
    EMPTY_ERROR: 'Dieses Feld muss ausgefüllt werden.',
    POSTCODE_EMPTY_ERROR: 'PLZ muss ausgefüllt werden.',
    EMPTY_EMAIL: 'Bitte gib deine E-Mail-Adresse ein.',
    INVALID_EMAIL: 'Bitte gib eine gültige E-Mail-Adresse an.',
    MAX_N_CHARS_COUNT_ERROR: (n: number) => `Bitte gib maximal ${n.toLocaleString('de-DE')} Zeichen ein.`, // de-AT generates 10 000 instead of 10.000
    ONLY_NUMBERS_ALLOWED_SHORT: 'Bitte nur Zahlen eingeben.',
    INCOMPLETE_POSTCODE_ERROR: 'PLZ ist unvollständig.',
    POSTCODE_TOO_LONG_ERROR: 'PLZ ist zu lang.',
    ONLY_NUMBERS_ALLOWED: 'Bitte nur Zahlen eingeben.',
    INVALID_PRICE_STRING: 'Ungültiger Preis.',
    INVALID_PRICE_ZERO_STRING: 'Ungültiger Preis. Der Preis muss größer als 0 sein.',
}

/** returns true is the string is not empty or undefined */
export const isNonEmpty = (value?: string): value is string => {
    if (typeof value === 'undefined') {
        return false
    }

    return value.trim().length !== 0
}

/** returns true if the string contains html code or if it is empty or undefined */
export const isHTML = (value?: string) => {
    if (typeof value === 'undefined') {
        return false
    }

    return /<[a-z/][\s\S]*>/i.test(value)
}

/** returns true if the two parameters are strings and are the same */
export const isTextEqual = (value1?: string, value2?: string) => {
    if (typeof value1 === 'undefined' || typeof value2 === 'undefined') {
        return false
    }

    return value1 === value2
}

export const isChecked = (value?: boolean) => {
    if (typeof value === 'undefined') {
        return false
    }

    return value !== false
}

/** returns true if the lenght of the string is longer than the minLength passed */
export const hasMinLength = (minLength: number, value?: string) => {
    if (typeof value === 'undefined') {
        return false
    }

    return value.trim().length >= minLength
}

/** returns true if the lenght of the string is smaller than the maxLength passed */
export const hasMaxLength = (maxLength: number, value?: string) => {
    if (typeof value === 'undefined') {
        return true
    }

    return value.trim().length <= maxLength
}

export const isValidOptionalWhCode = (whCode?: string) => {
    if (!whCode || whCode.trim().length === 0) {
        return true // optional
    }

    // allow leading and trailing whitespace
    const whCodeRegex = /^\s*[1-9Jj][0-9]{0,9}\s*$/
    return whCodeRegex.test(whCode)
}

export const isValidWhCode = (whCode?: string, topic?: string) => {
    if (!whCode || whCode.trim().length === 0) {
        return !isWhCodeRequired(topic)
    }

    // allow leading and trailing whitespace
    const whCodeRegex = /^\s*[1-9Jj][0-9]{0,9}\s*$/
    return whCodeRegex.test(whCode)
}

export const isWhCodeRequired = (topic?: string) => {
    return topic === 'PAYLIVERY_BUY_SELL' || topic === 'UNZULAESSIGE_ANZEIGE'
}

/** returns true if the string is a valid email format */
export const isValidMailString = (mailString?: string) => {
    if (!mailString) {
        return false
    }

    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/
    return emailRegex.test(mailString.trim())
}

export const isValidAlphanumString = (alphanumString?: string) => {
    if (!alphanumString) {
        return false
    }

    const regex = /^[a-zA-Z0-9]+$/
    return regex.test(alphanumString.trim())
}

export const isValidDateString = (dateString?: string) => {
    if (!dateString) {
        return false
    }

    const regex = /^(3[0,1]|[1-2][0-9]|0?[1-9])\.(0?[1-9]|1[0-2])\.(19[0-9][0-9]|2[0-9][0-9][0-9])$/
    return regex.test(dateString.trim())
}

export const isValidDate = (dateString?: string, format: string = 'DD.MM.YYYY', strict: boolean = true) => {
    if (!dateString) {
        return false
    }

    return dayjs(dateString, format, strict).isValid()
}

export const isValidPhoneNumberString = (phoneNumberString?: string) => {
    // currently our api only supports phone numbers consisting only of numbers
    return isNumberString(phoneNumberString)
}

export const isNumberString = (numberString?: string) => {
    if (!numberString) {
        return false
    }

    const numberRegex = /^[0-9]+$/i
    return numberRegex.test(numberString.trim())
}

export const isDecimalNumberStringWithUnlimitedFractions = (decimalNumberString?: string) => {
    if (!decimalNumberString) {
        return false
    }

    // the api allows both , and .
    const decimalNumberRegex = /^\d*([.,]\d*)?$/
    return decimalNumberRegex.test(decimalNumberString.trim())
}

export const isDecimalNumberString = (decimalNumberString?: string) => {
    if (!decimalNumberString) {
        return false
    }

    // the api allows both , and .
    const decimalNumberRegex = /^\d*[.,]?\d{0,2}$/
    return decimalNumberRegex.test(decimalNumberString.trim())
}

export const isValidDateFormatWithDoubleDigits = (date?: string) => {
    if (!date) {
        return false
    }

    const dateRegex = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/
    return dateRegex.test(date)
}

export const emailValidationMessage = (email?: string) => {
    if (!isNonEmpty(email)) {
        return genericValidationErrorMessages.EMPTY_EMAIL
    } else if (!isValidMailString(email)) {
        return genericValidationErrorMessages.INVALID_EMAIL
    } else {
        return undefined
    }
}

export const maxLengthValidationMessage = (n: number, value?: string) =>
    hasMaxLength(n, value) ? undefined : genericValidationErrorMessages.MAX_N_CHARS_COUNT_ERROR(n)

export const austrianPostCodeValidation = (isMandatory: boolean, addressPostcode?: string) => {
    if (!isNonEmpty(addressPostcode)) {
        return isMandatory ? genericValidationErrorMessages.POSTCODE_EMPTY_ERROR : undefined
    } else if (addressPostcode && !isNumberString(addressPostcode)) {
        return genericValidationErrorMessages.ONLY_NUMBERS_ALLOWED_SHORT
    } else if (addressPostcode && addressPostcode.trim().length < 4) {
        return genericValidationErrorMessages.INCOMPLETE_POSTCODE_ERROR
    } else if (addressPostcode && addressPostcode.trim().length > 4) {
        return genericValidationErrorMessages.POSTCODE_TOO_LONG_ERROR
    } else {
        return undefined
    }
}

export const isValidUrl = (url: string | undefined, withHttp: boolean) => {
    if (!url) {
        return false
    }

    if (withHttp) {
        return /^https?:\/\/[^\s]+[.][^\s]+$/.test(url)
    } else {
        return /^[^\s]+[.][^\s]+$/.test(url)
    }
}

export const hasOnlyDecimalNumberCharacters = (numberString?: string) => {
    if (!numberString) {
        return false
    }

    // the api allows both , and .
    const numberRegex = /^[0-9.,]+$/i
    return numberRegex.test(numberString.trim())
}

export const hasValidPriceFormat = (priceString?: string) => {
    if (!priceString) {
        return false
    }

    // the api allows both , and .
    const priceRegex = /^[0-9]{1,}([.,][0-9]{0,2})?$/i
    return priceRegex.test(priceString.trim())
}

export const priceValidationMessage = (isMandatory: boolean, allowZero: boolean, priceString?: string) => {
    if (!isNonEmpty(priceString)) {
        return isMandatory ? genericValidationErrorMessages.EMPTY_ERROR : undefined
    } else if (!hasOnlyDecimalNumberCharacters(priceString)) {
        return genericValidationErrorMessages.ONLY_NUMBERS_ALLOWED
    } else if (!hasValidPriceFormat(priceString)) {
        return genericValidationErrorMessages.INVALID_PRICE_STRING
    } else if (!allowZero && parseFloat(priceString.replace(',', '.')) === 0) {
        return genericValidationErrorMessages.INVALID_PRICE_ZERO_STRING
    } else {
        return undefined
    }
}

export const eurotaxValidationMessage = (eurotaxString: string) => {
    if (!isNonEmpty(eurotaxString)) {
        return genericValidationErrorMessages.EMPTY_ERROR
    } else if (!hasOnlyDecimalNumberCharacters(eurotaxString)) {
        return genericValidationErrorMessages.ONLY_NUMBERS_ALLOWED
    } else if (eurotaxString.length > 6) {
        return 'Maximal 6 Ziffern eingeben.'
    } else {
        return undefined
    }
}
